import styled from "styled-components";

interface ImgProps {
  top?: boolean;
  right?: boolean;
  left?: boolean;
  bottom?: boolean;
  rotated?: boolean;
  heightSize?: number;
  widthSize?: number;
}

export const Img = styled.img<ImgProps>`
  position: absolute;
  top: ${({ top }) => (top ? 0 : undefined)};
  right: ${({ right }) => (right ? 0 : undefined)};
  bottom: ${({ bottom }) => (bottom ? 0 : undefined)};
  left: ${({ left }) => (left ? 0 : undefined)};
  width: ${({ widthSize }) => widthSize && `${widthSize}vmin`};
  height: ${({ heightSize }) => heightSize && `${heightSize}vh`};
  transform: ${({ rotated }) => rotated && "rotate(180deg)"};
  z-index: -100;
  opacity: 0.8;
`;
