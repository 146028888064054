import React from "react";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import {
  Text,
  CircularProgress,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import styled from "styled-components";

import { useAssetsLoading } from "../assetsLoader";
import { TransitionIn, Timer, Modal } from "../components";
import { useTranslation } from "../translations";
import { useResponsiveSizes } from "../responsive";
import emiybeto from "../_assets/emiybeto.mp4";
import us from "../_assets/us.jpg";

import { NotFound } from "./NotFound";

export const HomeRoute = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        <Home />
      </Route>
      <NotFound />
    </Switch>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  align-self: center;
  margin: auto;
  text-align: center;

  video,
  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    min-height: 100%;
    min-width: 100vw;
    z-index: -2;
    opacity: 0.2;
  }
`;

const NavBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 10px;
`;

const Home = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const t = useTranslation();
  const { headingSize } = useResponsiveSizes();

  const { vidsLoaded, fontsLoaded, imgsLoaded } = useAssetsLoading(
    [emiybeto],
    [us]
  );

  const allFontsAreLoaded = Object.keys(fontsLoaded).every(
    (key) => fontsLoaded[key]
  );

  if (!allFontsAreLoaded) {
    return (
      <Wrapper>
        <CircularProgress
          isIndeterminate
          color="teal.500"
          size="100px"
          thickness="4px"
        />
      </Wrapper>
    );
  }

  const showVideo = vidsLoaded[emiybeto];
  const showImg = imgsLoaded[us] && !showVideo;

  return (
    <>
      <Modal
        content={t("sendUsAnEmail")}
        confirmText="Ok"
        onClose={onClose}
        onAbort={onClose}
        isOpen={isOpen}
      />
      <NavBar>
        <Button onClick={onOpen}>{t("contactUs")}</Button>
      </NavBar>
      <Wrapper>
        {showVideo && (
          <TransitionIn>
            <video loop muted autoPlay={true} src={emiybeto} />
          </TransitionIn>
        )}
        {showImg && (
          <TransitionIn>
            <img alt="us" src={us} />
          </TransitionIn>
        )}
        <TransitionIn>
          <Timer size="big" />
          <Text fontSize={headingSize} color="gold" fontFamily="Allura">
            {t("forOurWedding")}
          </Text>
        </TransitionIn>
      </Wrapper>
    </>
  );
};
