import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from{
    opacity: 0;
  } to {
    opacity: 1
  }
`;

export const TransitionIn = styled.div`
  animation: ${fadeIn} 0.6s ease-in;
`;
