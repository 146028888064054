import React from "react";
import { Text } from "@chakra-ui/react";
import styled from "styled-components";

import sad from "../../../_assets/sad.png";
import { TransitionIn } from "../../../components";
import { useTranslation } from "../../../translations";
import { useResponsiveSizes } from "../../../responsive";

const Emoji = styled.img<{ size?: string }>`
  margin: auto;
  height: ${({ size }) => size};
`;

export const Thanks = () => {
  const t = useTranslation();
  const { headingSize, subHeadingSize } = useResponsiveSizes();

  return (
    <TransitionIn>
      <Text fontSize={subHeadingSize} color="gold">
        {t("thanks")}
      </Text>
      <Emoji alt="sad" src={sad} size={headingSize} />
    </TransitionIn>
  );
};
