import React from "react";
import {
  Text,
  Button,
  Textarea,
  useToast,
  VStack,
  useDisclosure,
  ButtonGroup as ChakraButtonGroup,
} from "@chakra-ui/react";
import styled from "styled-components";

import { useUpdateRSVP } from "../../../api";
import { RSVPAPIResponseBody } from "../../../types";
import { Modal, ButtonWrapper, TransitionIn } from "../../../components";
import { useTranslation } from "../../../translations";
import { useResponsiveSizes } from "../../../responsive";

import { FormValues, STEP } from "../types";

const ButtonsPanel = styled.div<{ addTopPadding: boolean }>`
  display: flex;
  flex-direction: row;
  padding-top: ${({ addTopPadding }) => (addTopPadding ? "10px" : undefined)};

  > :nth-child(n + 2) {
    margin-left: 20px;
  }
`;

const ButtonGroup = styled(ChakraButtonGroup)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;

  > p {
    position: absolute;
    top: -24px;
  }
`;

export const Confirm = ({
  formValues,
  setFormValues,
  setFormStep,
  invitation,
  updateRSVP,
}: {
  formValues: FormValues;
  setFormValues: (
    setter: (previousFormValues: FormValues) => FormValues
  ) => void;
  setFormStep: (step: STEP) => void;
  invitation: RSVPAPIResponseBody["get"]["invitation"];
  updateRSVP: ReturnType<typeof useUpdateRSVP>;
}) => {
  const toast = useToast();
  const t = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { subHeadingSize, buttonSize } = useResponsiveSizes();

  const { maxNumOfAdults, maxNumOfKids } = invitation ?? {};
  const { rsvp, numOfAdults, numOfKids, comments } = formValues;

  const submitForm = async () => {
    if (
      rsvp === undefined ||
      numOfAdults === undefined ||
      (maxNumOfKids !== 0 && numOfKids === undefined) ||
      comments === undefined
    ) {
      throw new Error(
        "Cannot submit, one value is undefined" +
          JSON.stringify({ rsvp, numOfAdults, numOfKids, comments })
      );
    }

    const { success } = await updateRSVP({
      rsvp,
      numOfAdults,
      numOfKids: numOfKids ?? 0,
      comments,
    });

    if (!success) {
      throw new Error("Update not successful :/");
    }
  };

  return (
    <TransitionIn>
      <Modal
        content={t("doubleCheck")}
        confirmText={t("confirm")}
        abortText={t("cancel")}
        onClose={async () => {
          try {
            await submitForm();
            setFormStep("confirmed_yep");
            onClose();
          } catch (_e) {
            toast({
              title: "Error",
              description: t("tryAgain"),
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
        }}
        onAbort={onClose}
        isOpen={isOpen}
      />
      <VStack spacing="30px">
        <Text fontSize={subHeadingSize} color="gold">
          {t("thatsGreat")}
        </Text>
        <ButtonsPanel addTopPadding={!!maxNumOfKids}>
          <ButtonGroup isAttached>
            {!!maxNumOfKids && <Text>{t("adults")}</Text>}
            {Array(maxNumOfAdults)
              .fill(null)
              .map((_, idx) => (
                <Button
                  key={idx}
                  size={buttonSize}
                  colorScheme="teal"
                  variant={numOfAdults === idx + 1 ? "solid" : "outline"}
                  onClick={() => {
                    setFormValues((previousValues) => ({
                      ...previousValues,
                      numOfAdults: idx + 1,
                    }));
                  }}
                >
                  {idx + 1}
                </Button>
              ))}
          </ButtonGroup>
          {!!maxNumOfKids && (
            <ButtonGroup isAttached>
              <Text>{t("kids")}</Text>
              {Array(maxNumOfKids + 1)
                .fill(null)
                .map((_, idx) => (
                  <Button
                    key={idx}
                    size={buttonSize}
                    colorScheme="teal"
                    variant={numOfKids === idx ? "solid" : "outline"}
                    onClick={() => {
                      setFormValues((previousValues) => ({
                        ...previousValues,
                        numOfKids: idx,
                      }));
                    }}
                  >
                    {idx}
                  </Button>
                ))}
            </ButtonGroup>
          )}
        </ButtonsPanel>
        <Textarea
          maxHeight={300}
          placeholder={t("requirements")}
          value={formValues.comments}
          onChange={({ target: { value: text } }) => {
            setFormValues((pv) => ({ ...pv, comments: text.slice(0, 800) }));
          }}
        />
        <ButtonWrapper>
          <Button
            size={buttonSize}
            onClick={() => {
              if (
                (maxNumOfKids === 0 && numOfAdults !== undefined) ||
                (numOfKids !== undefined && numOfAdults !== undefined)
              ) {
                onOpen();
                return;
              }

              const errorMessage =
                t("pleaseSelect") + (maxNumOfKids === 0 ? "." : t("andAlso"));

              toast({
                title: "Error",
                description: errorMessage,
                status: "warning",
                duration: 3000,
                isClosable: true,
              });
            }}
          >
            {t("confirm")}
          </Button>
          <Button
            size={buttonSize}
            variant="ghost"
            onClick={() => {
              setFormStep("welcome");
            }}
          >
            {t("cancel")}
          </Button>
        </ButtonWrapper>
      </VStack>
    </TransitionIn>
  );
};
