import React, { useState, useCallback, useContext } from "react";

import { Language } from "../types";

const INTIAL_LANGUAGE = navigator?.language.indexOf("es") !== -1 ? "EN" : "ES";

const TranslationsContext = React.createContext<Language>(INTIAL_LANGUAGE);

const TranslationsContextSwitcher = React.createContext<
  React.Dispatch<React.SetStateAction<Language>>
>(() => null);

export const TranslationsProvider: React.FC = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState<Language>(
    INTIAL_LANGUAGE
  );

  return (
    <TranslationsContextSwitcher.Provider value={setCurrentLanguage}>
      <TranslationsContext.Provider value={currentLanguage}>
        {children}
      </TranslationsContext.Provider>
    </TranslationsContextSwitcher.Provider>
  );
};

export const useChangeTranslationLanguage = () =>
  useContext(TranslationsContextSwitcher);

export const useTranslation = () => {
  const language = useContext(TranslationsContext);

  const t = useCallback(
    (translationKey: TranslationStringKey, num?: number) => {
      const isPlural = num === 0 || (num !== undefined && num > 1);
      return `${
        language === "EN"
          ? englishText[translationKey]
          : spanishText[translationKey]
      }${isPlural ? "s" : ""}`;
    },
    [language]
  );

  return t;
};

const englishText = {
  ourWedding:
    "Thank you for confirming your assistance to our wedding which will take place the 1st of September of 2021 at the ",
  yesICan: "Yes, let's do it!",
  noICant: "Sorry, I won't make it...",
  youAreConfirming: "You are about to confirm that you will ",
  no: "NOT",
  willMakeIt: " be able to assist.",
  confirm: "Confirm",
  cancel: "Go back",
  thanks: "Thanks for confirming, we will miss you!",
  thatsGreat: "Great! How many guests should we expect?",
  requirements:
    "(optional) Any alergies/dietary requirementes or comments?\n-max 800 characters-",
  doubleCheck: 'Once you click "Confirm" you won\'t be able to go back',
  weWillBeWaiting: "Awesome! We will see you in:",
  days: "day",
  hours: "hour",
  minutes: "minute",
  seconds: "second",
  and: "and",
  tryAgain: "Something has gone wrong, please try again later.",
  pleaseSelect: "Please select number of adults",
  andAlso: " and number of kids to continue.",
  adults: "Adults",
  kids: "Kids",
  leaveAComment: "Leave a comment (optional)",
  forOurWedding: "for our wedding",
  sendUsAnEmail: "Send us an email to hello@emiybeto.com to contact us!",
  contactUs: "Contact Us",
};

type TranslationStringKey = keyof typeof englishText;

const spanishText: Record<TranslationStringKey, string> = {
  ourWedding:
    "Gracias por confirmar su asistencia a nuestra boda que tendrá lugar el miércoles 1ero de septiembre del 2021 en el ",
  yesICan: "¡Allí nos vemos!",
  noICant: "Lo siento, no podré asistir...",
  youAreConfirming: "Está a punto de confirmar que ",
  no: "NO",
  willMakeIt: " podrá asistir.",
  confirm: "Confirmar",
  cancel: "Regresar",
  thanks: "Gracias por su confirmación ¡le echaremos de menos!",
  thatsGreat: "¡Súper! ¿Cuántos invitados asistirán?",
  requirements:
    "(opcional) ¿Alergias/intolerancias alimenticias o comentarios?\n-máx 800 caracteres-",
  doubleCheck: "Luego de confirmar no podrá modificar su respuesta.",
  weWillBeWaiting: "¡Chévere! Nos veremos en:",
  days: "día",
  hours: "hora",
  minutes: "minuto",
  seconds: "segundo",
  and: "y",
  tryAgain: "Ha ocurrido un error, por favor intente de nuevo luego.",
  pleaseSelect: "Por favor seleccione el número de adultos",
  andAlso: " y número de niños para continuar.",
  adults: "Adultos",
  kids: "Niños",
  leaveAComment: "Deja un comentario (opcional)",
  forOurWedding: "para nuestra boda",
  sendUsAnEmail: "Envíanos un email a hello@emiybeto.com para contactarnos!",
  contactUs: "Contáctanos",
};
