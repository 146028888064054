import useFetch from "use-http";

import { apiBaseUrl } from "./base";

import { RSVPAPIResponseBody, RSVPRequestBody } from "../types";

export const useFetchInvitation = (id: string, key: string) => {
  return useFetch<RSVPAPIResponseBody["get"]>(
    `${apiBaseUrl}/rsvp/${id}`,
    {
      headers: {
        authorization: `Bearer ${key}`,
      },
    },
    [id]
  );
};

export const useUpdateRSVP = (id: string, key: string) => {
  return useFetch<RSVPAPIResponseBody["update"]>(`${apiBaseUrl}/rsvp/${id}`, {
    headers: {
      authorization: `Bearer ${key}`,
    },
  }).put as (
    values: RSVPRequestBody["update"]
  ) => Promise<RSVPAPIResponseBody["update"]>;
};
