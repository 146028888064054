import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import styled from "styled-components";
import { ChakraProvider } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";

import { initAnalytics } from "./analytics";
import { RSVPRoute } from "./pages/RSVP";
// import { DashRoute } from "./pages/Dash";
import { HomeRoute } from "./pages/Home";
import { AssetsLoaderProvider } from "./assetsLoader";
import { TranslationsProvider } from "./translations";

const config = {
  initialColorMode: "dark",
  useSystemColorMode: false,
};

const theme = extendTheme({
  config,
  fonts: { body: "Josefin Slab" },
  colors: { gold: "gold" },
});

const AppWrapper = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

const Routes = () => (
  <Router>
    <Switch>
      <Route path="/rsvp">
        <RSVPRoute />
      </Route>
      {/* <Route path="/dash">
        <DashRoute />
      </Route> */}
      <Route path="/">
        <HomeRoute />
      </Route>
    </Switch>
  </Router>
);

const App = () => {
  useEffect(() => {
    initAnalytics();
  }, []);

  return (
    <AssetsLoaderProvider>
      <ChakraProvider theme={theme}>
        <TranslationsProvider>
          <AppWrapper>
            <Routes />
          </AppWrapper>
        </TranslationsProvider>
      </ChakraProvider>
    </AssetsLoaderProvider>
  );
};

export default App;
