import React, { useState } from "react";
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";

interface ModalProps {
  title?: string;
  content: React.ReactNode;
  confirmText: string;
  abortText?: string;
  isOpen: boolean;
  onClose: () => Promise<void> | void;
  onAbort: () => void;
}

export const Modal = ({
  title,
  content,
  confirmText,
  abortText,
  onClose,
  onAbort,
  isOpen,
}: ModalProps) => {
  const [loading, setLoading] = useState(false);
  return (
    <ChakraModal
      motionPreset="slideInBottom"
      isCentered
      isOpen={isOpen}
      onClose={onAbort}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{content}</ModalBody>

        <ModalFooter>
          <Button
            isLoading={loading}
            isDisabled={loading}
            mr={3}
            onClick={async () => {
              setLoading(true);
              await onClose();
              setLoading(false);
            }}
          >
            {confirmText}
          </Button>
          {abortText && (
            <Button variant="ghost" onClick={onAbort}>
              {abortText}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
};
