import { useBreakpointValue } from "@chakra-ui/react";

export const useResponsiveSizes = () => {
  const headingSize = useBreakpointValue({
    xs: "12vmin",
    base: "12vmin",
    sm: "9vmin",
    md: "9vmin",
    lg: "9vmin",
    xl: "9vmin",
  });

  const subHeadingSize = useBreakpointValue({
    xs: "5vmin",
    base: "5vmin",
    sm: "4vmin",
    md: "4vmin",
    lg: "4vmin",
    xl: "4vmin",
  });

  const buttonSize = useBreakpointValue({
    xs: undefined,
    base: undefined,
    sm: "lg",
    md: "lg",
    lg: "lg",
    xl: "lg",
  });

  return { buttonSize, headingSize, subHeadingSize };
};
