import React from "react";
import { Redirect } from "react-router-dom";

export const NotFound = () => (
  <Redirect
    to={{
      pathname: "/",
    }}
  />
);
