import styled from "styled-components";

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  flex-wrap: wrap;
  justify-content: center;
  > * {
    margin: 4px;
  }
`;
