import React from "react";
import { Text, VStack } from "@chakra-ui/react";
import styled from "styled-components";

import party from "../../../_assets/party.png";
import { TransitionIn, Confetti, Timer } from "../../../components";
import { useTranslation } from "../../../translations";
import { useResponsiveSizes } from "../../../responsive";

const Emoji = styled.img<{ size?: string }>`
  height: ${({ size }) => size};
`;

export const SeeYa = () => {
  const t = useTranslation();
  const { headingSize, subHeadingSize } = useResponsiveSizes();

  return (
    <TransitionIn>
      <Confetti />

      <VStack spacing="30px">
        <Text fontSize={subHeadingSize} color="gold">
          {t("weWillBeWaiting")}
        </Text>
        <Timer />
        <Emoji alt="party" src={party} size={headingSize} />
      </VStack>
    </TransitionIn>
  );
};
