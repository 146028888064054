import React, { useEffect, useRef, useState } from "react";
import { Text } from "@chakra-ui/react";
import { differenceInSeconds } from "date-fns";

import { useTranslation } from "../translations";
import { useResponsiveSizes } from "../responsive";

export const Timer = ({ size }: { size?: "big" }) => {
  const t = useTranslation();

  const [secondsToWedding, setSeconstToWedding] = useState(0);
  const interval = useRef<null | number | ReturnType<typeof setInterval>>(null);
  const { headingSize, subHeadingSize } = useResponsiveSizes();

  useEffect(() => {
    const calculateSecondsToDate = () => {
      const currentTime = new Date();
      const weddingDate = new Date(2021, 8, 1, 18, 0, 0);
      setSeconstToWedding(differenceInSeconds(weddingDate, currentTime));
    };
    calculateSecondsToDate();
    interval.current = setInterval(calculateSecondsToDate, 1000);
    return () => {
      if (interval.current !== null) {
        clearInterval(interval.current as any);
      }
    };
  }, []);

  const days = Math.floor(secondsToWedding / (60 * 60 * 24));
  const daysInSeconds = days * 60 * 60 * 24;

  const hours = Math.floor((secondsToWedding - daysInSeconds) / (60 * 60));
  const hoursInSeconds = hours * 60 * 60;

  const minutes = Math.floor(
    (secondsToWedding - daysInSeconds - hoursInSeconds) / 60
  );
  const minutesInSeconds = minutes * 60;

  const seconds = Math.floor(
    secondsToWedding - daysInSeconds - hoursInSeconds - minutesInSeconds
  );

  return (
    <Text
      fontSize={size === "big" ? headingSize : subHeadingSize}
      color="gold"
      fontFamily={size === "big" ? "Allura" : undefined}
    >
      {`${days} ${t("days", days)}, ${hours} ${t(
        "hours",
        hours
      )}, ${minutes} ${t("minutes", minutes)} ${t("and")} ${seconds} ${t(
        "seconds",
        seconds
      )}`}
    </Text>
  );
};
