import useFetch from "use-http";

import { apiBaseUrl } from "./base";

import { InvitationsAPIResponseBody } from "../types";

function getToken() {
  const name = "access_token=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let c of ca) {
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const useFetchInvitations = () => {
  return useFetch<InvitationsAPIResponseBody["getAll"]>(
    `${apiBaseUrl}/invitations`,
    {
      headers: {
        authorization: `Bearer ${getToken()}`,
      },
    },
    []
  );
};
